<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["getActivtitiesBaseInfo", "getCities","getCountries","getCommercialActivities","getUsersList"]),
  },
  created() {
    this.getActivtitiesBaseInfo();
    this.getCities();
    this.getCountries();
    this.getCommercialActivities();
    this.getUsersList()
  },
};
</script>
